// @ts-nocheck
import React from "react";
import Ico_Facebook from "../asesst/Icons/Ico_facebook";
import Ico_Instagram from "../asesst/Icons/Ico_instagram";
import Ico_Twitter from "../asesst/Icons/Ico_twitter";
import { fadein } from "../Styles/keyframes";
import Flex from "../Styles/styledComponent/Flex";
import Text from "../Styles/styledComponent/Text";
import Ico_whatsapp from "../asesst/Icons/Ico_whatsapp";

function Main() {
  const clickWhatsapp = () => {
    window.open("https://wa.me/+5548992002932", "_blank");
  };

  return (
    <Flex
      dir={"column"}
      justify="center"
      align="center"
      css={{
        width: "100%",
        height: "100%",
        opacity: "0",
        animation: `1s ease-in 1s 1 both ${fadein}`,
      }}
    >
      <Text
        css={{
          display1: "800",
          fontSize: 95,
          textAlign: "center",
          "@bp2": {
            display2: "800",
          },
          "@bp4": {
            display3: "800",
          },
          "@bp5": {
            headline1: "800",
          },
        }}
      >
        SPARKING CODE<br></br>
      </Text>

      <Text
        css={{
          paddingTop: "$1",
          subhead1: "500",
          textAlign: "center",
          fontSize: 20,
          marginTop: 20,
          "@bp4": {
            paddingTop: "$1",
            subhead1: "400",
          },
        }}
      >
        Converting Visions into
        <br></br>Technologies
      </Text>

    </Flex>
  );
}

export default Main;
