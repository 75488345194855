import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Flex from "./Styles/styledComponent/Flex";
import globalStyle from "./Styles/globalCss";
import Nav from "./components/nav";
import Main from "./components/main";
import PrivacyHelpRS from "./components/privacy";
import PrivacyNexNote from "./components/PrivacyNexNote";

function App() {
  globalStyle();

  return (
    <Router>
      <Flex
        id="wrapper"
        data-testid="app"
        css={{
          position: "relative",
          padding: " $2 $10",
          background: "linear-gradient(135deg, #0B1F0E, #00E920)",
          "@bp1": {
            padding: " $2 $3",
          },
          "@bp2": {
            padding: " $2 $5",
          },
          "@bp5": {
            padding: " $2 $3",
          },
        }}
      >
        <Flex
          css={{
            backgroundImage:
              "radial-gradient(circle, rgba(0, 233, 32, 0.15) 10%, transparent 10%), radial-gradient(circle, rgba(0, 233, 32, 0.15) 10%, transparent 10%)",
            backgroundSize: "20px 20px",
            opacity: 0.1,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
          }}
        ></Flex>
        <Nav />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/politica-privacidade" element={<PrivacyHelpRS />} />
          <Route path="/politica-privacidade-nexnote" element={<PrivacyNexNote />} />
        </Routes>
      </Flex>
    </Router>
  );
}

export default App;
