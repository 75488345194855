import React from "react";
import Flex from "../Styles/styledComponent/Flex";

const PrivacyHelpRS: React.FC = () => {
  return (
    <Flex
      id="privacy-policy"
      css={{
        justifyContent: 'center',
        alignItems: 'center',
        width:"100%",
        height:"100%",
      }}
    >
      <div style={{ fontFamily: 'Arial, sans-serif' }}>
        <h1 style={{ marginBottom: '15px', fontWeight: 'bold' }}>Política de Privacidade</h1>
        <p style={{ marginBottom: '20px' }}>
          O aplicativo Ajuda RS, fornece informações sobre contatos de emergência e outros serviços de assistência como uma forma de auxiliar usuários em situações de emergência ou para facilitar o acesso a informações sobre doações. Esta política de privacidade tem como objetivo informar os usuários sobre nossas práticas em relação à privacidade, que são mínimas dado o escopo do Aplicativo.
        </p>
        <p style={{ marginBottom: '5px' }}>
          <strong style={{ fontWeight: 'bold' }}>1. Não Coleta de Dados Pessoais</strong>
        </p>
        <p style={{ marginBottom: '10px' }}>
          O Aplicativo não coleta ou armazena quaisquer dados pessoais de seus usuários. Os usuários podem acessar e utilizar o Aplicativo sem a necessidade de fornecer qualquer informação pessoal.
        </p>
        <p style={{ marginBottom: '5px' }}>
          <strong style={{ fontWeight: 'bold' }}>2. Informações Fornecidas pelo Aplicativo</strong>
        </p>
        <p style={{ marginBottom: '10px' }}>
          O Aplicativo fornece informações sobre números de telefone e endereços de instituições de ajuda, como polícia, bombeiros e organizações de doação. Essas informações são coletadas de fontes públicas ou são fornecidas diretamente pelas instituições.
        </p>
        <p style={{ marginBottom: '5px' }}>
          <strong style={{ fontWeight: 'bold' }}>3. Uso das Informações</strong>
        </p>
        <p style={{ marginBottom: '10px' }}>
          As informações fornecidas pelo Aplicativo são destinadas a ser usadas para facilitar o contato com serviços de emergência e instituições de ajuda. O Aplicativo não utiliza essas informações para qualquer outra finalidade.
        </p>
        <p style={{ marginBottom: '5px' }}>
          <strong style={{ fontWeight: 'bold' }}>4. Compartilhamento de Informações</strong>
        </p>
        <p style={{ marginBottom: '10px' }}>
          Como o Aplicativo não coleta dados pessoais, não há compartilhamento de informações pessoais de usuários com terceiros.
        </p>
        <p style={{ marginBottom: '5px' }}>
          <strong style={{ fontWeight: 'bold' }}>5. Segurança</strong>
        </p>
        <p style={{ marginBottom: '10px' }}>
          Tomamos medidas adequadas para proteger o Aplicativo e as informações nele contidas contra acesso não autorizado, alteração ou destruição.
        </p>
        <p style={{ marginBottom: '5px' }}>
          <strong style={{ fontWeight: 'bold' }}>6. Alterações na Política de Privacidade</strong>
        </p>
        <p style={{ marginBottom: '10px' }}>
          Podemos atualizar esta política de privacidade ocasionalmente para refletir quaisquer mudanças em nossas práticas ou por exigências legais. Encorajamos os usuários a revisarem nossa política periodicamente.
        </p>
        <p style={{ marginBottom: '5px' }}>
          <strong style={{ fontWeight: 'bold' }}>7. Contato</strong>
        </p>
        <p style={{ marginBottom: '20px' }}>
          Se você tiver qualquer dúvida ou preocupação sobre esta política de privacidade ou suas práticas, por favor, entre em contato conosco pelo email: [luan.evieira43@gmail.com].
        </p>
      </div>
    </Flex>
  );
};

export default PrivacyHelpRS;
