import React from "react";
import Flex from "../Styles/styledComponent/Flex";

const PrivacyNexNote: React.FC = () => {
  return (
    <Flex
      id="privacy-policy"
      css={{
        justifyContent: 'center',
        alignItems: 'center',
        width: "100%",
        height: "100%",
      }}
    >
      <div style={{ fontFamily: 'Arial, sans-serif', color: 'white' }}>
        <h1 style={{ marginBottom: '15px', fontWeight: 'bold' }}>Política de Privacidade</h1>
        <p style={{ marginBottom: '20px' }}>
          A NexNote respeita a privacidade de seus usuários e está comprometida em proteger suas informações pessoais. Esta Política de Privacidade explica como coletamos, utilizamos e protegemos os dados dos usuários no uso do nosso aplicativo de bloco de notas.
        </p>
        <p style={{ marginBottom: '5px' }}>
          <strong style={{ fontWeight: 'bold' }}>1. Coleta de Informações</strong>
        </p>
        <p style={{ marginBottom: '10px' }}>
          O NexNote exige que os usuários realizem login para acessar suas notas. Para isso, coletamos apenas informações essenciais, como o endereço de e-mail e a senha, que são usadas exclusivamente para autenticar o acesso à conta e garantir a segurança do seu conteúdo.
        </p>
        <p style={{ marginBottom: '5px' }}>
          <strong style={{ fontWeight: 'bold' }}>2. Uso das Informações</strong>
        </p>
        <p style={{ marginBottom: '10px' }}>
          As informações de login (e-mail e senha) são usadas apenas para permitir o acesso seguro à sua conta e às suas notas. Não usamos esses dados para outros fins, como marketing ou compartilhamento com terceiros.
        </p>
        <p style={{ marginBottom: '5px' }}>
          <strong style={{ fontWeight: 'bold' }}>3. Compartilhamento de Informações</strong>
        </p>
        <p style={{ marginBottom: '10px' }}>
          O NexNote não compartilha, vende ou aluga as informações pessoais dos usuários a terceiros. Seus dados de login são mantidos em segurança e utilizados exclusivamente para o funcionamento do aplicativo.
        </p>
        <p style={{ marginBottom: '5px' }}>
          <strong style={{ fontWeight: 'bold' }}>4. Segurança</strong>
        </p>
        <p style={{ marginBottom: '10px' }}>
          Adotamos medidas de segurança apropriadas para proteger suas informações contra acesso não autorizado, alteração ou destruição. Embora tomemos todas as precauções, é importante que os usuários também protejam suas credenciais de login.
        </p>
        <p style={{ marginBottom: '5px' }}>
          <strong style={{ fontWeight: 'bold' }}>5. Alterações na Política de Privacidade</strong>
        </p>
        <p style={{ marginBottom: '10px' }}>
          Podemos atualizar esta Política de Privacidade periodicamente para refletir mudanças nas nossas práticas ou por requisitos legais. Recomendamos que os usuários consultem esta política regularmente para se manterem informados sobre as atualizações.
        </p>
        <p style={{ marginBottom: '5px' }}>
          <strong style={{ fontWeight: 'bold' }}>6. Contato</strong>
        </p>
        <p style={{ marginBottom: '20px' }}>
          Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade ou sobre a segurança dos seus dados, entre em contato conosco pelo e-mail: [luan.evieira43@gmail.com].
        </p>
      </div>
    </Flex>
  );
};

export default PrivacyNexNote;
